import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';


export const CONDITIONS_LIST = [
    { value: "is-equal", label: "Is equal" },
    { value: "is-not-equal", label: "Is not equal" },
    { value: "contains", label: "Contains" },
    { value: "does-not-contain", label: "Does not contain" }
];

export const CONDITIONS_FUNCTIONS = {
    // search method base on conditions list value
    "is-equal": function (value: any, filterdValue: any) {
        return value.toLowerCase() == filterdValue.toLowerCase();
    },
    "is-not-equal": function (value: any, filterdValue: any) {
        return value.toLowerCase() != filterdValue.toLowerCase();
    },
    "contains": function (value: any, filterdValue: any) {
        if (typeof value === 'string' && typeof filterdValue === 'string') {
            return value.toLowerCase().includes(filterdValue.toLowerCase());
        }
        return false;
    },
    "does-not-contain": function (value: any, filterdValue: any) {
        if (typeof value === 'string' && typeof filterdValue === 'string') {
            return !value.toLowerCase().includes(filterdValue.toLowerCase());
        }
        return false;
    },
};

@Component({
    selector: 'new-lib-table-filter-headers',
    templateUrl: './new-filter.component.html',
})
export class NewTableFilterComponent implements OnInit, OnChanges {

    @Input() column: string = '';
    @Input() columnData: any;
    @Input() activeFilters: any[] = [];
    @Output() filterChange = new EventEmitter<any[]>();
    @Output() clearFilter = new EventEmitter<void>();
    @Output() sortChange = new EventEmitter<string>();

    @ViewChild('select') select: MatSelect;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor() { }

    selectAll: boolean = true;
    selectedValues: any[] = [];
    currentSortDirection: string = 'asc';
    filterApplied: boolean = false;
    filtersCleared: boolean = false;
    searchText: string;

    conditionValue: string = '';
    selectedCondition: string = '';
    conditionOptions = CONDITIONS_LIST;

    filteredColumnData: any[] = []; // Data filtered by the search query

    ngOnInit(): void {
        this.selectedValues = [...this.columnData];
        this.filteredColumnData = [...this.columnData];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.filtersCleared && changes['columnData']) {
            // When columnData changes, update filteredColumnData
            this.filteredColumnData = [...this.columnData];
            this.selectedValues = [...this.columnData]; // Sync selected values with updated columnData
        }
        if (changes['activeFilters']) {
            this.applyActiveFilters();
          }
    }

    // Apply the active filters
    applyActiveFilters(): void {
        if (this.activeFilters.length > 0) {
            this.filteredColumnData = this.columnData.filter((item: any) =>
                this.activeFilters.includes(item)
            );
        } else {
            // If no active filters, show all the data
            this.filteredColumnData = [...this.columnData];
        }
    }

    onMenuOpened(): void {
        this.applyActiveFilters();
        // // When the menu is opened, filter the available column data based on all active filters
        // if (this.column) {
        //     if (Object.keys(this.activeFilters).length === 0) {
        //         // If no filters are applied (activeFilters is empty), show all values for this column
        //         this.filteredColumnData = [...this.columnData];
        //     } else {
        //         // Filter the column data based on the active filters of all columns
        //         const filteredRows = this.columnData.filter((row: any) => {
        //             return Object.keys(this.activeFilters).every((activeColumn: any) => {
        //                 // Skip the current column when applying the active filters
        //                 if (activeColumn === this.column) return true;
        //                 const columnFilter = this.activeFilters[activeColumn];
        //                 return columnFilter.length === 0 || columnFilter.includes(row[activeColumn]);
        //             });
        //         });

        //         // Update the filtered column data based on the filtered rows
        //         this.filteredColumnData = Array.from(new Set(filteredRows.map((item: any) => item[this.column])));
        //     }
        // }
    }

    // Update the filtered column data based on the search query
    onSearchChange(): void {
        this.filtersCleared = false;
        const query = this.searchText.toLowerCase();
        if (query) {
            this.filteredColumnData = this.columnData.filter((item: any) =>
                item.toString().toLowerCase().includes(query)
            );
            this.selectedValues = [...this.filteredColumnData];
        } else {
            // Reset to show all data if search query is cleared
            this.filteredColumnData = [...this.columnData];
        }
    }

    // Apply condition filter
    applyConditionFilter(): void {
        let filteredData: any[] = [];
        this.filtersCleared = false;
        switch (this.selectedCondition) {
            case 'equals':
                filteredData = this.columnData.filter((item: any) => item === this.conditionValue);
                break;
            case 'contains':
                filteredData = this.columnData.filter((item: any) => item.toLowerCase().includes(this.conditionValue.toLowerCase()));
                break;
            case 'greaterThan':
                filteredData = this.columnData.filter((item: any) => item > this.conditionValue);
                break;
            case 'lessThan':
                filteredData = this.columnData.filter((item: any) => item < this.conditionValue);
                break;
            default:
                filteredData = this.columnData;
                break;
        }

        this.filteredColumnData = filteredData;
        this.selectedValues = [...this.filteredColumnData];
    }

    // // Apply search filter (independent from condition filter)
    // applySearchFilter(): void {
    //     this.filteredColumnData = this.columnData.filter((item: any) =>
    //         item.toString().toLowerCase().includes(this.searchText.toLowerCase())
    //     );
    // }

    // Emit changes when the filter is updated
    applyFilter(): void {
        this.filterApplied = true;
        this.filtersCleared = false;
        this.filterChange.emit(this.selectedValues);
        if (this.trigger) {
            this.trigger.closeMenu();
        }
    }

    // Check if a value is selected
    isSelected(value: any): boolean {
        return this.selectedValues.includes(value);
    }

    // Toggle "select all" option
    toggleSelectAll(): void {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.selectedValues = [...this.filteredColumnData];  // Select all items
        } else {
            this.selectedValues = [];  // Clear selection
        }
        // this.applyFilter();
    }

    // Toggle selection of a value
    toggleValueSelection(value: any, checked: boolean): void {
        if (checked) {
            // Add to selected values if not already included
            if (!this.selectedValues.includes(value)) {
                this.selectedValues.push(value);
            }
        } else {
            // Remove from selected values
            this.selectedValues = this.selectedValues.filter(v => v !== value);
        }
    }

    // Emit event to clear filter for this column
    clearColumnFilter(): void {
        this.searchText = '';
        this.selectedCondition = '';
        this.conditionValue = '';
        this.selectedValues = []; // Reset selected values
        this.applyFilter(); // Emit cleared filter values
        this.clearFilter.emit(); // Notify the parent component to clear this column's filter
        // this.filteredColumnData = [...this.columnData];
        // this.selectedValues = [...this.filteredColumnData];
        this.filterApplied = false;
        this.filtersCleared = true;
    }

    sortData(direction: string): void {
        this.sortChange.emit(direction);
    }


}


