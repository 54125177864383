import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'app/globals-services/globals.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
    this.openUserGuide()
  }

  openUserGuide() {

    var url = '';

    //const url = "https://www.thebhive.net/user-guide-languages"
    url = "https://www.thebhive.net/campus";


    if (GlobalsService.serverEnvironment === 'China') {
      // url = "https://www.thebhive.cn/the-bhive-campus-cn";
      url = "https://www.thebhive.cn/the-bhive-campus-recordings";
    }

    window.open(url, "_blank");

  }
}
