import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalsService } from 'app/globals-services/globals.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VocCalculationsService {

  constructor(private http: HttpClient,
    private globalService: GlobalsService,) { }
  
  baseHref = environment.baseHref;



  getVocList() {
    return this.http.get<any[]>(this.baseHref + '/getVocList');
  }
  getAllVocCalculations() {
    return this.http.get<any[]>(this.baseHref + '/getVocCalculations');
  }
  deleteVocCalculation(id: number) {
    return this.http.post<any>(`${this.baseHref}/deleteVocCalculations/${id}`, {});
  }

  

  uploadVocCalculations(fileBlob: Blob, fileName: string, organization_id) {
    if (!fileBlob) {
      console.error('No file to upload');
      return; // Early exit
    }
    if (!organization_id) {
      console.error('organization_id is undefined');
      return; // Early exit
    }
    const formData: FormData = new FormData();
    formData.append('file', fileBlob, fileName);
    formData.append('Id', organization_id.toString());

    // Return the observable here
    return this.http.post(this.baseHref + '/uploadVocCalculations/' + organization_id, formData, { responseType: 'text' });
  }
  addVocCalculations(dateFrom, dateTo, totalVOCpteTon, level, totalConsumption, totalVOCconsumption, percTotalVocConsump, excelFileName, excelPath) {

    const reqObj = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      totalVOCpteTon: totalVOCpteTon,
      level: level,
      totalConsumption: totalConsumption,
      totalVOCconsumption: totalVOCconsumption,
      percTotalVocConsump: percTotalVocConsump,
      excelFileName: excelFileName,
      path:excelPath
    };

    return this.http.post(this.baseHref + '/saveVocCalculations', reqObj);
  }
}
