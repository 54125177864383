<div *ngIf="homePage" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">    
        <div>

            <div class="flex items-center justify-between w-full ">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    >
                    {{ "VOC.PAGE_HEADER" | translate }}
                </h2>

                
                    <button (click)="newCalculation(true)" class="choose-button">
                        {{'VOC.NEW_CALCULATION' | translate }} </button> 
            </div>



                <div  class="mb-8"
                    >
                    
                    {{ "VOC.PAGE_SUB_HEADER1" | translate }}<a href="https://downloads.roadmaptozero.com/output/air_emissions_guidelines" target="_blank">{{ "VOC.PAGE_SUB_HEADER2" | translate }}</a>
            </div>

           

            <div class="content">
                <table class="table tablefixed">
                    <thead style="background-color:#8080802e;">
                        <tr>
                            <th>
                                {{
                                "VOC.CREATED_ON"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.DATE"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.VOC_PTE_TONS"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.LEVEL"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.CONSUMPTION"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.VOC_CONSUMPTION"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.PERCENTAGE_CONSUMPTION"
                                | translate
                                }}
                            </th>
                            <th>
                                {{
                                "VOC.ACTION"
                                | translate
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr *ngFor="let result of vocCalculations ">
                        <td> {{ result["created_on"] | date:'dd.MM.yyyy' }} </td>
                        <td> {{ result[ "date_from" ]| date:'dd.MM.yyyy' }}  - {{result[ "date_to" ] | date:'dd.MM.yyyy' }} </td>
                        <td> {{ result[ "voc_pte" ]  }} </td>
                        <td> {{ result[ "level" ]  }} </td>
                        <td> {{ result[ "consumption" ]}} </td>
                        <td> {{ result[ "voc_consumption" ] }} </td>
                        <td> {{ result[ "voc_consumption_perct" ] | number : "1.2-2"}} </td>
                        <td style="min-width:120px;">
                            <a (click)="download(result)"><mat-icon style="margin-right:10px" matTooltip="Download" svgIcon="mat_outline:file_download"></mat-icon></a> 
                            <a (click)="view(result)"><mat-icon style="margin-right:10px"  matTooltip="View" svgIcon="heroicons_solid:eye"></mat-icon></a> 
                            <a (click)="deleteVoc(result.id)"><mat-icon  matTooltip="Delete" svgIcon="mat_outline:delete"></mat-icon></a>
                        </td>
                         </tr>     
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div *ngIf="newVoc" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">    
        <div class="content header">

            <div class="flex items-center justify-between w-full ">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    >
                    {{ "VOC.PAGE_HEADER" | translate }}
                </h2>

                
                    <button (click)="newCalculation(false)" class="back">
                        {{'VOC.BACK' | translate }} </button> 
            </div>



                <div class="mt-3 mb-8"
                    >
                    {{ "VOC.PAGE_SUB_HEADER1" | translate }}<a href="https://downloads.roadmaptozero.com/output/air_emissions_guidelines" target="_blank">{{ "VOC.PAGE_SUB_HEADER2" | translate }}</a>

            </div>   
            <div>
            <p class="mb-3"><b>{{"VOC.STEP1" | translate}}:</b> {{"VOC.STEP1_DESCRIPTION" | translate}}</p>
                <mat-form-field appearance="fill" style="width:218px; color:#808080">
                    <mat-label i18n="Inventories report date range label">{{
                        "INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE" | translate
                        }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [formControl]="startDate" />
                        <input matEndDate placeholder="End date" [formControl]="endDate"
                            (dateChange)="changeEndDate($event, 'Not Activity')" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                    <p><b>{{"VOC.STEP2" | translate}}</b>: {{"VOC.STEP2_DESCRIPTION" | translate}}</p>
                <div class="mt-4 p-4 bg-gray-200">
                    <div class="flex justify-between">
                        <div class="pl-4">
                            <strong>{{
                                "VOC.OPTION_1_TITLE" | translate }}
                            </strong>
                            <br>
                            <span>{{
                                "VOC.OPTION_1_SUBTITLE" | translate }}
                            </span>
                        </div>
                        <button class="downloadUploadButton flex justify-end" (click)="exportData()">
                            {{
                            "VOC.BTN_DOWNLOAD_TEMPLATE" | translate }}
                        </button>
                    </div>
                </div>
                <p class="mt-4"><b>{{"VOC.STEP3" | translate}}:</b> </p>
                        <ul style="list-style-type: disc; padding-left: 20px;">
                        <li>{{"VOC.STEP3_DESCRIPTION1" | translate}}</li>
                        <li>{{"VOC.STEP3_DESCRIPTION2" | translate}}</li>
                        <li>{{"VOC.STEP3_DESCRIPTION3" | translate}}</li>
                    </ul>
                <p class="mt-4"><b>{{"VOC.STEP4" | translate}}:</b> {{"VOC.STEP4_DESCRIPTION" | translate}} </p>
                <div class="mt-4 p-4 bg-gray-200">

                    <div class=" flex justify-between">
                        <div class="pl-4">
                            <strong>{{
                                "VOC.OPTION_2_TITLE" | translate }}
                            </strong>
                            <br>

                           <!-- File input element -->
                            <input class="file-upload-input"
                                placeholder='{{"VOC.SELECT_FILE" | translate }}'
                                type="file" 
                                (change)="addFile($event.target.files)" 
                                id="file" 
                                #fileUploadVariable
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                <label class="chooseFile" for="file">Choose file</label>
                            <span *ngIf="fileName" style="margin-left:8px;">Selected file: <i>{{ fileName }}</i></span>

                            <!-- If no file is selected, show this message -->
                            <span *ngIf="!fileName" style="margin-left:8px;">No file chosen</span>

                        </div>

                        <button class="downloadUploadButton flex justify-end" (click)="upload()">
                            {{
                            "VOC.BTN_UPLOAD_TEMPLATE" | translate }}
                        </button>
                    </div>
                </div>
                    <div class="mt-3">{{
                    "VOC.FOOTER_NOTE" | translate }}
                    </div>
                    <div class="mt-3" style="color:#008000d1;" *ngIf="uploadComplete" > <mat-icon 
                    style="color:#008000d1; vertical-align: middle!important"
                    [svgIcon]="'mat_outline:check_circle'"></mat-icon>
                     {{"VOC.FILE_READY" | translate }}
                    </div>

                    <div  class="mt-4">
                        <div><b>{{"VOC.STEP5" | translate}}:</b> {{"VOC.STEP5_DESCRIPTION" | translate}} </div>
                    </div>
                    <div class="mt-2"> <mat-checkbox [(ngModel)]="isChecked"></mat-checkbox>
                        {{"VOC.CONFIRMATION" | translate}} </div>
                        <div class="mt-2 calculateVOC"><button (click)="calculateVoc()" [disabled]="!isChecked || !uploadComplete" class="choose-button">
                        {{'VOC.CALCULATE_VOC' | translate }} </button> </div>
                    
                
            </div>
        </div>
        <div *ngIf="displayedData.length > 0" class="mt-5 content header">

            <div class="flex items-center justify-between w-full ">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    >
                    {{ "VOC.RESULT" | translate }}
                </h2>

            
            </div>

            <div class="flex items-center justify-between w-full">
                <div 
                    >
                    {{ "VOC.RESULT_SUBHEADER1" | translate }}  {{ this.totalVOCpteTon}}  ton
            </div>
                    <button (click)="downloadResults()" class="choose-button">
                        {{'VOC.DOWNLOAD' | translate }} </button>                     
            </div>
            <div style="color:red"><i>{{ "VOC.RESULT_SUBHEADER2" | translate }}</i></div>
            <div class="mt-3 levelBox">{{ this.level}}</div>

           

<div class="mt-4">
  <table *ngIf="displayedData.length > 0" class="table table-bordered">
    <thead class="thead-dark" style="background-color:#8080802e;;">
      <tr>
        <th *ngFor="let col of displayedColumns">{{ col }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of paginatedData">
        <td *ngFor="let col of displayedColumns">
            <ng-container *ngIf="col === 'VOC emissions (PTE of VOCs) (kg)'; else normalCell">
            {{ row[col] | number: '1.2-2' }}
            </ng-container>
            <ng-template #normalCell>
            {{ row[col] }}
            </ng-template>
        </td>
        </tr>
    </tbody>
  </table>

  <!-- Pagination Controls -->
  <nav *ngIf="totalPages > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1" (click)="changePage(currentPage - 1)">
        <a class="page-link paginationClass"  href="javascript:void(0)">Previous</a>
      </li>
      <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage" (click)="changePage(page)">
        <a class="page-link paginationClass"  href="javascript:void(0)">{{ page }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)">
        <a class="page-link paginationClass"  href="javascript:void(0)">Next</a>
      </li>
    </ul>
  </nav>
</div>

        </div>
    </div>
</div>

<div *ngIf="viewResult" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">    
        <div class="content header">

            <div class="flex items-center justify-between w-full ">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    >
                    {{ "VOC.PAGE_HEADER" | translate }}
                </h2>

                
                    <button (click)="newCalculation(false)" class="back">
                        {{'VOC.BACK' | translate }} </button> 
            </div>



                <div class="mt-3 mb-8"
                    >
                    {{ "VOC.PAGE_SUB_HEADER1" | translate }}<a href="https://downloads.roadmaptozero.com/output/air_emissions_guidelines" target="_blank">{{ "VOC.PAGE_SUB_HEADER2" | translate }}</a>

            </div>   
        

            <div class="flex items-center justify-between w-full ">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    >
                    {{ "VOC.RESULT" | translate }}
                </h2>

            
            </div>

            <div class="flex items-center justify-between w-full">
                <div 
                    >
                    {{ "VOC.RESULT_SUBHEADER1" | translate }}  {{ this.totalVOCpteTon}}  ton
            </div>
                    <button (click)="downloadResults()" class="choose-button">
                        {{'VOC.DOWNLOAD' | translate }} </button>                     
            </div>
            <div style="color:red"><i>{{ "VOC.RESULT_SUBHEADER2" | translate }}</i></div>
            <div class="mt-3 levelBox">{{ this.level}}</div>

           

            <div class="mt-4">
                <table *ngIf="displayedData.length > 0" class="table table-bordered">
                    <thead class="thead-dark" style="background-color:#8080802e;;">
                    <tr>
                        <th *ngFor="let col of displayedColumns">{{ col }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of paginatedData">
                        <td *ngFor="let col of displayedColumns">
                            <ng-container *ngIf="col === 'VOC emissions (PTE of VOCs) (kg)'; else normalCell">
                            {{ row[col] | number: '1.2-2' }}
                            </ng-container>
                            <ng-template #normalCell>
                            {{ row[col] }}
                            </ng-template>
                        </td>
                        </tr>
                    </tbody>
                </table>

                <!-- Pagination Controls -->
                <nav *ngIf="totalPages > 1">
                    <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="currentPage === 1" (click)="changePage(currentPage - 1)">
                        <a class="page-link paginationClass"  href="javascript:void(0)">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage" (click)="changePage(page)">
                        <a class="page-link paginationClass"  href="javascript:void(0)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)">
                        <a class="page-link paginationClass"  href="javascript:void(0)">Next</a>
                    </li>
                    </ul>
                </nav>
            </div>
        </div>

    </div>
</div>
