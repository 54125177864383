import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRole } from 'app/user-interface';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { E } from '@angular/cdk/keycodes';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

@Injectable()
export class GlobalsService {
    server = environment.server;
    baseHref = environment.baseHref;
    otherServer = environment.otherServer;
    profile = {};
    gatewayUrl = environment.gatewayUrl;

    // gatewayServiceMockUrl=environment.incheckServiceMockUrl

    bhiveIncheckServiceUrl = environment.incheckServiceUrl;

    faqUrl = environment.faqUrl;
    homeUrl = environment.homeUrl;
    otherHomeUrl = environment.otherHomeUrl;

    metaBaseURL = environment.metaBaseURL;
    serverLocation = environment.serverLocation;
    recaptchaKey = environment.recaptchaKey;

    unprotectedURL = ['signup', 'sign-up', 'sign-in', 'login', ''];

    mockUrl = 'http://127.0.0.1:5000';

    isLoggedIn = false;

    brands = [];

    isValidWalmartId: Boolean = false;
    role1 = 1;
    role2 = 2;
    public static userRole;
    public userRoles: ReplaySubject<UserRole> = new ReplaySubject<UserRole>(2);
    public languageChangeSubject: ReplaySubject<string> = new ReplaySubject<string>();

    public static isKontoorSupplier: Boolean = false;
    public static iswalmartSupplier: Boolean = false;
    public static isCarterSupplier: Boolean = false;
    public static isTheChildrenPlaceSupplier: Boolean = false;
    public static isKomarBrandSupplier: Boolean = false;
    public static isOekotex: Boolean = false;
    public static isMts: Boolean = false;
    public static isKontoor: Boolean = false;
    public static isOtto: Boolean = false;
    public static isAldi: Boolean = false;
    public static isHofer: Boolean = false;
    public static isNike: Boolean = false;
    public static isAldiSourcing: Boolean = false;
    public static isAldiImporter: Boolean = false;
    public static isCandA: Boolean = false;
    public static isInditex: Boolean = false;
    public static isBrandZdhcMember: Boolean = false;
    public static isTCP: Boolean = false;
    public static isRedNice: Boolean = false;
    public static isMiles: Boolean = false;
    public static isDummy: Boolean = false;
    public static isAldiImporterBrand: Boolean = false;
    public static isHm: Boolean = false;
    public static zdhcGatewaySubscriptionStatus: Boolean = false;
    public static isOneJeanswearGroupSupplier: Boolean = false;

    loaderStateUpdate: Subject<boolean> = new Subject<boolean>();


    public static isWalmartUser: Boolean = false;
    public static isTuvUser: Boolean = false;
    public static isBluwinUser: Boolean = false;

    public static subscriptionTypeAdmin: string;
    static isHAndMSupplier: Boolean;
    static isOttoSupplier: Boolean;

    public static isDPPBrand: Boolean = false;
    public static isDPPBrandChina: Boolean = false;
    public static isDPPBrandSupplier: Boolean = false;
    public static isDPPBrandSupplierChina: Boolean = false;
    public static isSdsScreeningBrand: Boolean = false;


    public static isDPPVendor: Boolean = false;

    loadUserProfile$ = new Subject();
    static isSupplierOfAldiHofer: Boolean;
    static isSupplierOfAldiEinkauf: Boolean;
    static isSupplierOfSdsScreeningBhive: Boolean;
    public static issupplierOfOTBAndOther: Boolean = false;
    static isLindexBrand: Boolean = false;
    static isDummyBestsellerBrand: Boolean = false;
    static isTakkoBrand: boolean = false;
    static isVF: any;
    static isSupplierOfPuma: Boolean;





    static isPumaInScopeSupplier: boolean = false;
    //static isSupplierOfPuma: any;
    public static readonly serverEnvironment = environment.serverLocation;
    static pageSizeOptions = [25, 50, 100];
    static pageSizeDefault = 100;

    constructor(private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService
    ) { }


    showSpinner() {
        this.loaderStateUpdate.next(true);
    }

    hideSpinner() {
        this.loaderStateUpdate.next(false);
    }

    whoAmI() {
        return this.http.get(this.baseHref + '/whoAmI');
    }

    getAllPartners() {
        return this.http.get<any[]>(this.baseHref + '/getAllPartners');
    }

    get userRoles$(): Observable<UserRole> {
        console.log(this.userRoles.asObservable());
        return this.userRoles.asObservable();
    }
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            //     errorMessage = Error: ${error.error.message};
        } else {
            // server-side error
            // errorMessage = Error Code: ${error.status}\nMessage: ${error.message};
        }
        this.hideSpinner();
        //  this.toastService.error(errorMessage);

        alert(errorMessage);

        return throwError(errorMessage);

    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
