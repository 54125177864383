<h2 mat-dialog-title></h2>

<mat-dialog-content>
    <mat-tab-group  mat-stretch-tabs [selectedIndex]="tabIndex" (selectedTabChange)="selectedTabChanged($event);" class="mt-1" >
        <mat-tab *ngFor="let tab of newTabs; let index = index" [label]="tab['name']">
            <ng-template matTabContent>
                <mat-card>
                    <button mat-icon-button id="more_button" [matMenuTriggerFor]="tabMenu">
                        <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                    </button>
                    <mat-menu #tabMenu="matMenu" id="more_menu">
                        <button mat-menu-item
                        (click)="exportData(this.factor_analytics_popup_tableDataList.data)">
                            <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                            <span>Export Data</span>
                        </button>
                    </mat-menu>
                    <mat-card-content>
                        <div class="h-full scroll">
                            <table mat-table [dataSource]="factor_analytics_popup_tableDataList" #factor_analytics_popup_tableMatSort="matSort" matSort>

                                <ng-container matColumnDef="factoryName" sticky>
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.factoryName}}
                                    </td>
                                </ng-container>
                                    
                                <ng-container matColumnDef="chemical_name" sticky>
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.chemical_name}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="manufacturer" sticky>
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.manufacturer}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="country">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.country}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="scan_date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
                                    <td mat-cell *matCellDef="let formula" >
                                        {{formula.scan_date}}
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="isVF" matColumnDef="chem_iq_level">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ChemIQ Level</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.chem_iq_level }}
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="isAldi" matColumnDef="bluwin_sds_verified">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>SDS Screened</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.sds_screened }}
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="isZdhcBrand" matColumnDef="gateway_subscription_status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Subscription Status</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.gateway_subscription_status }}
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="isZdhcBrand || this.data.chartData[0].complianceValue.includes('Gateway') " matColumnDef="gateway_level">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Level</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.gateway_level}}
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="!isZdhcBrand && this.data.chartData[0].complianceValue.includes('Gateway')" matColumnDef="gateway_level">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{loggedInBrandName}} Compliance</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.gateway_level}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="basic_chemical">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Commodities</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.basic_chemical}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="zdhc_mrsl">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zdhc Mrsl</th>
                                    <td mat-cell *matCellDef="let formula" >
                                        {{formula.zdhc_mrsl}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="eco_passport">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eco Passport</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.eco_passport }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="toxfmd">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxfmd</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.toxfmd }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="scivera">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scivera</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.scivera }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="test_report">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Report</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.test_report }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="c2c">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>C2C</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.c2c }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="bluesign">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bluesign</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.bluesign }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="gots">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gots</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.gots }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="inditex_AboveThreeYears">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>The List > 3</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.inditex_AboveThreeYears }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="inditex_BelowThreeYears">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>The List < 3 </th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.inditex_BelowThreeYears }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="zdhc_grading">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zdhc Grading</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.zdhc_grading }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="third_party_verifier">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Third Party Verifier</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.third_party_verifier }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="green_screen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Green Screen</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.green_screen }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="factor_analytics_popup_table; sticky: true;"></tr>
                                <tr mat-row *matRowDef="let row; columns: factor_analytics_popup_table;"
                                    ></tr>
                            </table>
                            <div *ngIf="hasNotData" style="text-align:center;">
                                No data is available
                                </div>
                            <!-- <div *ngIf="factor_analytics_popup_tableDataList.data.length === 0">No data</div> -->
                            <loading-spinner *ngIf="isLoading"></loading-spinner>
                        </div>
                        <mat-paginator #factor_analytics_popup_tableMatPaginator="matPaginator" [pageSizeOptions]="[20]"
                            showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                    <mat-card-footer>
                        <small>* Tables show raw data, meaning all duplicates</small>
                        </mat-card-footer>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>  
</mat-dialog-content>

<mat-dialog-actions style="justify-content: end;">
    <button class="mat-raised-button" mat-dialog-close>Close</button>
</mat-dialog-actions>