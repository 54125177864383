<!-- <div class="header">  -->
<button mat-button [matMenuTriggerFor]="menu" (click)="onMenuOpened()">
    <mat-icon class="filter_icon" [ngStyle]="{ color: filterApplied   ? '#05AFF0' : '' }">filter_list</mat-icon>
</button>
<!-- </div> -->
<mat-menu #menu="matMenu" >
    <div class="menu-title">
        {{ column }}
    </div>
    <div mat-filter-item class="table-filter-headers">Sort
        <button mat-button class="table-filter-sort-buttons" (click)="sortData('asc')"
            [class.active]="currentSortDirection === 'asc'"><mat-icon>arrow_upward</mat-icon>A-Z</button>
        <button mat-button class="table-filter-sort-buttons" (click)="sortData('desc')"
            [class.active]="currentSortDirection === 'desc'"><mat-icon>arrow_downward</mat-icon>Z-A</button>
    </div>
    <mat-divider></mat-divider>
    <div class="table-filter-headers">
        Filter & Search
    </div>
    <div mat-filter-item class="table-filter-conditions">
        <mat-form-field style="margin-right:10px" class="table-filter-smaller-form-field">
            <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' [(value)]="selectedCondition">
                <mat-option *ngFor="let condition of conditionOptions"
                    [value]="condition.value">{{condition.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="table-filter-smaller-form-field">
            <input matInput placeholder="Value" [(ngModel)]="conditionValue" (input)="applyConditionFilter()">
        </mat-form-field>
    </div>
    <div mat-filter-item class="table-filter-search-and-filter">
        <mat-icon class="table-filter-search-and-filter-icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchText" matTooltip="Search By {{ column }}"
            class="table-filter-search-and-filter-text" placeholder="Search By {{ column }}"
            (input)="onSearchChange()" />
    </div>

    <!-- Select All Checkbox -->
    <mat-checkbox style="font-weight: 700; padding-left: 10px;" [checked]="selectAll" (click)="$event.stopPropagation()" (change)="toggleSelectAll()">Select All</mat-checkbox>

    <!-- Display filtered column data as options for selection -->
    <div class="scrollable-checkbox-list">
        <div *ngFor="let value of filteredColumnData">
            <mat-checkbox [checked]="isSelected(value)" (click)="$event.stopPropagation()" (change)="toggleValueSelection(value, $event.checked)">
                {{ value }}
            </mat-checkbox>
        </div>
    </div>

    <!-- Search Table -->
    <!-- <div mat-filter-item class="table-filter-select-container">
        <table mat-table [dataSource]="filteredColumnData" class="table-filter-select">
            <!-- Checkbox Column --
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- Values Column --
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Select All</th>
                <td mat-cell *matCellDef="let element">{{element.value | conditionalNumberFormat }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height:unset;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height:unset;"
                (click)="selection.toggle(row)"></tr>
        </table>
    </div> -->

    <div mat-filter-item class="table-filter-apply-Search-Buttons">
        <button mat-raised-button (click)="clearColumnFilter()" class="table-filter-select-buttons-Clear">Clear</button>
        <button color="primary" mat-raised-button (click)="applyFilter()"
            class="table-filter-select-buttons-Search">Apply</button>
    </div>
</mat-menu>