<div class="header-general  flex justify-between">
  <div class="h3 m-0 p-2  ml-4">
    <span *ngIf="isTopicWiseUpload"> {{ 'PRODUCT_MODULE_PHASE_2.DOCUMENT_UPLOAD_COMPONENT.TAB.'+topicForHeader|
      translate }} -</span>
    {{
    "DOCUMENT_UPLOAD_COMPONENT.TITLE" | translate
    }}
  </div>

  <div class="mr-4">
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>


<div class="content">
  <div class="flex flex-row ">

    <div class="grid grid-cols-2 gap-2">
      <mat-form-field class="input-field-wrapper">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.NAME" | translate }}&nbsp;*</mat-label>
        <input matInput [(ngModel)]="document['name']" id="name" required>
      </mat-form-field>


      <mat-form-field class="input-field-wrapper"
        *ngIf="!isTopicWiseUpload && isChemicalDocument() && !data.selfDeclaration">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.TYPE" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['category']" id="type" required  (selectionChange)="onDocCategoryChange($event)">
          <mat-option *ngIf="!hasSds" value="SDS">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.SDS" |
            translate }}</mat-option>
          <mat-option *ngIf="!isVerificationDocumentUpload" value="TDS">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.TDS" |
            translate }}</mat-option>
          <mat-option value="Test Report">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.TEST"
            | translate }}</mat-option>
          <mat-option *ngIf="!isVerificationDocumentUpload" value="STeP Declaration Letter">{{
            "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.SELF_DECLARATION" |
            translate
            }}
          </mat-option>
          <mat-option value="Certificate">{{
            "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.CERTIFICATE" | translate
            }}</mat-option>
          <mat-option *ngIf="!isVerificationDocumentUpload" value="Other">{{
            "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.OTHER" |
            translate }}</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="input-field-wrapper" *ngIf="!isTopicWiseUpload && !isChemicalDocument()">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.TYPE" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['category']" id="type" required>
          <mat-option *ngIf="!this.testWater" value="Test Report">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.TEST" |
            translate }}</mat-option>
          <mat-option value="Audit Report">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.AUDIT" | translate }}</mat-option>
          <mat-option value="Certificate">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.CERTIFICATE" | translate
            }}</mat-option>
          <mat-option value="Policy">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.POLICY" | translate }}</mat-option>
          <mat-option value="SOP">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.SOP" | translate }}</mat-option>
          <mat-option value="Other">{{ "DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT.OTHER" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="input-field-wrapper"
        *ngIf=" document['topic']==='DPP_EXTERNAL_ORGANIZATION_LEVEL' || document['topic']==='DPP_INTERNAL_ORGANIZATION_LEVEL'">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.TYPE" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['category']" id="type" required>

          <mat-option *ngFor="let op of dppTemplateSupplierOrgLevelDoc" value="{{op.value}}">
            {{op.title}}
          </mat-option>



        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-field-wrapper"
        *ngIf="document['topic']==='DPP_EXTERNAL_PRODUCT_LEVEL' ||  document['topic']==='DPP_INTERNAL_PRODUCT_LEVEL'">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.TYPE" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['category']" id="type" required>

          <mat-option *ngFor="let op of dppTemplateSupplierProductLevelDoc" value="{{op.value}}">
            {{op.title}}
          </mat-option>
        </mat-select>



      </mat-form-field>


      <mat-form-field class="input-field-wrapper"
        *ngIf="document['topic']==='DPP_EXTERNAL_MATERIAL_FIBER_LEVEL' || document['topic']==='DPP_INTERNAL_MATERIAL_FIBER_LEVEL'">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.TYPE" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['category']" id="type" required>

          <mat-option *ngFor="let op of dppTemplateSupplierMaterialFiberLevelDoc" value="{{op.value}}">
            {{op.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-field-wrapper" *ngIf="!isTopicWiseUpload && !isChemicalDocument()">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.RESULT" | translate }}&nbsp;*</mat-label>
        <mat-select [(ngModel)]="document['passFail']" id="result" required>
          <mat-option value="true">{{ "DOCUMENT_UPLOAD_COMPONENT.RESULT_SELECT.PASSED" | translate }}</mat-option>
          <mat-option value="false">{{ "DOCUMENT_UPLOAD_COMPONENT.RESULT_SELECT.FAILED" | translate }}</mat-option>
          <mat-option value="null">{{ "DOCUMENT_UPLOAD_COMPONENT.RESULT_SELECT.NA" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-field-wrapper" *ngIf="isTopicWiseUpload">
        <mat-label> {{ "PRODUCT_MODULE_PHASE_2.SCOPE" | translate }} &nbsp;*</mat-label>
        <input matInput [(ngModel)]="document['scope']" id="scope" required>
      </mat-form-field>


      <mat-form-field class="input-field-wrapper" *ngIf="isTopicWiseUpload">
        <mat-label> {{ "PRODUCT_MODULE_PHASE_2.DOCUMENT_ID" | translate }} &nbsp;*</mat-label>
        <input matInput [(ngModel)]="document['documentID']" id="documentID" required>
      </mat-form-field>


    </div>


    <div class="grid grid-cols-2 gap-2">
      <mat-form-field class="input-field-wrapper">
        <mat-label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.ISSUE_DATE" | translate }}&nbsp;*</mat-label>
        <input matInput [matDatepicker]="picker1" [max]="document['expiryDate']" (dateChange)="updateIssueDate($event)"
          [(ngModel)]="issueDate" id="issue">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>

      </mat-form-field>



      <mat-form-field class="input-field-wrapper">
        <mat-label>
          {{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.EXPIRY_DATE" | translate }}
          <span *ngIf="isTopicWiseUpload ">*</span>
        </mat-label>
        <input matInput [matDatepicker]="picker2" [min]="document['issueDate']" (dateChange)="updateExpiryDate($event)"
          [(ngModel)]="expiryDate" id="expiry">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="document['category']==='SDS' " class="input-field-wrapper">
      <mat-label> Language</mat-label>
      <mat-select matNativeControl [(ngModel)]=" document['language'] ">
        <mat-option *ngFor=" let language of languageService[ 'availableLanguages' ] " [value]="language['name']">
          {{ language["name"] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>


  <div class="grid grid-cols-2 gap-2">
    <div class="mr-4" *ngIf="!isTopicWiseUpload ">
      <textarea class="w-full h-16 " rows="1" cols="14" wrap="soft"
        style="border: 1px solid lightgray !important;border-radius: 5px; " [(ngModel)]="document['comment']"
        id="comment" placeholder="Comment"></textarea>
    </div>

    <div class="flex items-center"
      *ngIf="showVerificationCheckbox &&  (  document['category']==='Certificate' || document['category']==='Test Report' )">
      <input [(ngModel)]="verificationDocumentCheck" type="checkbox">
      <span> &nbsp; Submit as verification document </span>
    </div>

    <div class="flex items-center"
      *ngIf="showVerificationCheckbox && isBluwinDependVerificationNeeded && document['category']==='SDS' ">
      <input [(ngModel)]="verificationDocumentCheck" type="checkbox" [disabled]="isFormulaVerified">
      <span [ngStyle]="{'color': isFormulaVerified ? 'gray' :   null  }"> &nbsp;Request SDS Screening </span>

    </div>

    <div *ngIf="showVerificationCheckbox && isBluwinDependVerificationNeeded && document['category']==='SDS' "
      class="col-span-2 border-2 border-dashed  rounded p-4 " style="border-color: #14bee1; ">
      <p style="color: #14bee1;">{{ 'SDS_SCREENING_NOTE'| translate }} </p>
    </div>

  </div>

  <br>
  <!-- <div class="grid grid-cols-2 gap-2"> -->

  <!-- <div class=" input-field-wrapper file-input-wrapper">
      <div class="inline-div">
        <label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.FILE_SELECT" | translate }}*</label>
        <input type="file" (change)="addFile($event.target.files)" id="file">
      </div>
      <div class="inline-div">
        <button *ngIf="hasFile()" (click)="clearContent()">
          <mat-icon class="clear-button-icon">cancel</mat-icon>
        </button>
      </div>
    </div> -->
  <div class="flex flex-col">
    <app-common-file-upload [accept]="'application/pdf'" [allowedFileExtension]="['pdf','PDF']"
      (onFileSelectEv)="onFileSelectEv($event)"></app-common-file-upload>

    <div class="mt-3">
      <input [(ngModel)]="docConditionCheck" type="checkbox">
      <span>
        &nbsp;I agree with T&C and aware that uploaded document will be visible to
        connected brands
      </span>
    </div>

    <span class="mt-3" *ngIf="document['category']=='Transaction Certificate'" style="color: red; ">
      {{ "TC_UPLOAD_NOTE" | translate }}
    </span>
  </div>
  


  <br>

  <div class="flex flex-row justify-end  ">
    <button class="btn btn-secondary" (click)="cancel()">
      {{ "DOCUMENT_UPLOAD_COMPONENT.BUTTONS.CANCEL" | translate }}
    </button>
    <button class="ml-4 btn btn-primary" (click)="upload()" [disabled]="!docConditionCheck">
      {{ "DOCUMENT_UPLOAD_COMPONENT.BUTTONS.UPLOAD" | translate }}
    </button>
  </div>



</div>